import { Card, Heading, CardHeader as UIKitCardHeader, CardBody, CardFooter } from '@pancakeswap/uikit'
import styled from 'styled-components'

const bannerList = [
  {
    name: 'FIST🚀🚀🚀',
    logo: 'https://www.iconaves.com/token_icon_request/67076a4461081168c2e04421_1728539204.png',
    link: 'https://fistswap.app/',
  },
  {
    name: '项目招募',
    logo: 'https://www.iconaves.com/token_icon_request/67076a4461081168c2e04421_1728539204.png',
    link: 'https://fistswap.app/',
  },
  {
    name: '项目招募',
    logo: 'https://www.iconaves.com/token_icon_request/67076a4461081168c2e04421_1728539204.png',
    link: 'https://fistswap.app/',
  },
  {
    name: '项目招募',
    logo: 'https://www.iconaves.com/token_icon_request/67076a4461081168c2e04421_1728539204.png',
    link: 'https://fistswap.app/',
  },
  {
    name: '项目招募',
    logo: 'https://www.iconaves.com/token_icon_request/67076a4461081168c2e04421_1728539204.png',
    link: 'https://fistswap.app/',
  },
  {
    name: '项目招募',
    logo: 'https://www.iconaves.com/token_icon_request/67076a4461081168c2e04421_1728539204.png',
    link: 'https://fistswap.app/',
  },
  {
    name: '项目招募',
    logo: 'https://www.iconaves.com/token_icon_request/67076a4461081168c2e04421_1728539204.png',
    link: 'https://fistswap.app/',
  },
]

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: repeat(6, 1fr);
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns: repeat(6, 1fr);
  }
`
const Col = styled.div`
  position: relative;
  width: 100px;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 100px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    width: 120px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    width: 150px;
  }
  min-height: 1px;
  margin: 5px;
`

const Adlist = () => {
  return (
    <Card style={{ marginTop: '20px' }}>
      <UIKitCardHeader>
        <Heading size="xl">优质项目推荐</Heading>
      </UIKitCardHeader>

      <CardBody>
        <Row style={{ marginTop: '0px' }}>
          {bannerList.map((banner, index) => {
            const childKey = `Banner${index}`
            return (
              <Col
                key={childKey}
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              >
                <a href={banner.link} target="_blank" rel="noopener noreferrer">
                  <img src={banner.logo} alt="banner4" />
                  <div style={{ marginTop: '10px', textAlign: 'center' }}>{banner.name}</div>
                </a>
              </Col>
            )
          })}
        </Row>
      </CardBody>
      <CardFooter>不做任何担保，请谨慎对待</CardFooter>
    </Card>
  )
}

export default Adlist
